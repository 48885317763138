import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '../CustomButton/CustomButton';
import styles from './CustomDialog.module.css';

export default function CustomDialog({ showDialog, _data, handleClick, ...props }) {
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        if (showDialog) {
            setOpen(showDialog)
        }
    }, [showDialog])

    const handleClose = () => {
        setOpen(false);
        handleClick(false)
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                fullScreen
                PaperProps={{
                    style: {
                        backgroundColor: '#222222',
                        boxShadow: 'none',
                        color: 'white'
                    },
                }}
            >
                <DialogTitle className={styles.dialogHeaderStyle} style={{ cursor: 'move', textAlign: 'center' }} id="draggable-dialog-title"
                >
                   <b> Cara Pemakaian Voucher</b>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: 'white', marginTop: '25px' }} className={styles.dialogBodyStyle}>
                        {_data.hasOwnProperty('description') && <React.Fragment>
                            {_data.description === '' ? (<span> Lyto merupakan pengembang sekaligus penerbit dari beragam game mobile asal Indonesia. Mereka terkenal berkat beragam game bergenre MMO (Massive Multiplayer Online) yang diterbitkan, seperti Ragnarok Online, Getamped, Seal Online, RF-Online, Idol Street, Perfect World dan CrossFire.
                                Pada laman utama game tersebut, pilih menu Beli Koin.
                                Masukkan ID dan Password akun Lyto pada kolom yang tersedia, kemudian tekan tombol LOGIN.
                                Pilih metode pembayaran Beli Koin Via Kartu Pembayaran (Fisik).
                                Masukkan kode voucher pada kolom yang telah disediakan.</span>) : (<span dangerouslySetInnerHTML={{__html: _data.description}}></span>)}
                        </React.Fragment>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton value="Tutup" handleClick={handleClose} size="42px"/>
                </DialogActions>
            </Dialog>
        </div>
    );
}