import { TextField } from '@mui/material';
import React, { useContext, useState, useEffect, useRef } from 'react';
import styles from './LandingPage.module.css';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { ContextData } from '../../ContextApi/ContextProvider/ContextProvider';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { localSearch } from '../../Utility/Utils';
import { fetchData } from '../../Utility/Network';
import defaultImg from '../../Assets/default.svg';
import ErrorDialog from '../../Components/ErrorDialog/ErrorDialog';

function LandingPage(props) {
    const input1 = useRef(null);
    const firstUpdate = useRef(true);
    const { setSelectedGame, setCardsDetails } = useContext(ContextData);
    const [search, setSearch] = useState({ searchValue: '' });
    const [cardList, setCardList] = useState([]);
    const [cardData, setCardData] = useState([])
    const [noData, setNoData] = useState(false)
    const [showError, setShowError] = useState(false);
    const handleClick = (_data) => {
        setSelectedGame(_data)
        setCardsDetails(_data.products)
        props.history.push(`/detailsPage/${_data.name}`)
    }
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            var filteredArray = localSearch(search.searchValue, cardData)
            if (filteredArray.length === 0) {
                setNoData(true)
                setCardList(filteredArray)
            } else {
                setNoData(false)
                setCardList(filteredArray)
            }
        }, 600);
        return () => clearTimeout(delayDebounceFn);
    }, [search.searchValue]);

    const handleChange = (event) => {
        setSearch({ [event.target.name]: event.target.value })
    }

    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        const payloadData = {
            "brandName": ""
        }
        try {
            const res = await fetchData(payloadData);        
            const data = res.data;

            if (res.status === 200) {
                if (data.success) {
                    setCardList(data.billers)
                    setCardData(data.billers)
                } else {
                    setShowError(true)
                }

            } else {
                setShowError(true)
            }
        } catch (error) {
            setShowError(true)
        }
        
    }
    // useEffect(() => {
    //     alert('hi')
    //     console.log(input1.current,'sandesh')
    //     input1.current.focus();
    // }, [])
    const handleClick1 = () => {
        input1.current.focus();
    };
    return (
        <div className={styles.landingPageContainer}>
            <TextField
                id="outlined-required"
                placeholder='Cari penyedia jasa...'
                value={setSearch.searchValue}
                name='searchValue'
                onChange={(e) => handleChange(e)}
                inputRef={input1}
                style={{
                    background: '#414141',
                    width: '100%',
                    borderRadius: '50px',
                    margin: '20px 0px',
                    height: '48px'
                }}
                InputProps={{
                    style: {
                        color: "white",
                        fontWeight: '700',
                        fontSize: '16px',
                        lineGeight: '24px',
                        outline: 'none',
                        height: '48px'
                    },
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{ color: '#9D9D9D' }} onClick={handleClick1} />
                        </InputAdornment>
                    )
                }}
            />
            {cardList.length > 0 && <div style={{ minHeight: "70vh" }}>
                <div className={styles.cardsContainer}>
                    {cardList.map((item, idx) => {
                        return (
                            <div className={styles.cardsRow} key={idx}>
                                <div className={styles.individualCard}>
                                    <img src={item.logo === '' ? defaultImg : item.logo} style={{ width: '100%', borderRadius: '8px' }} onClick={() => handleClick(item)} key={idx} />
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>}
            {noData && <div className={styles.noDataFoundText} style={{ height: '70vh' }}>
                <p>Pencarian tidak ditemukan. Coba ulangi dengan kata kunci lainnya ya!</p>
            </div>}
            <div className={styles.bottomTextContainer}>
                <span>
                Produk atau jasa ini disediakan dan menjadi tanggung jawab Ayoconnect. Untuk layanan Customer Service, hubungi
                021 40011091 atau email ke info@ayopop.com.
                Cek Syarat & Ketentuan layanan lengkap <a href="https://bankmandiri.co.id/livin/vouchergame/syarat-dan-ketentuan?utm_source=urban-lifestyle&utm_medium=livinapp&utm_campaign=tnc-vouchergame" className={styles.anchorClass}>di sini.</a>
                </span>
            </div>
            {/* <div className={styles.blueFloatingDiv}>
            </div> */}
            <ErrorDialog _open={showError} _handleClose={setShowError} />
        </div>
    )
}

export default withRouter(LandingPage);
