import React from 'react';
import styles from './CustomButton.module.css';
import { Button } from '@mui/material';

function CustomButton({ value, handleClick, buttonDisabled = false, size, ...props }) {
    return (
        <Button variant="contained" size="large" style={{
            fontWeight: "bold", width: '100%', height: `${size}`, textTransform: 'capitalize', borderRadius: '50px', opacity: `${buttonDisabled ? '0.3' : '1'}`
        }} className={styles.customButtonStyle} onClick={handleClick} >
            {value}
        </Button>
    )
}

export default CustomButton;