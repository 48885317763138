import React, { Component, createContext } from 'react';
import { createTheme } from '@mui/material/styles';

export const ContextData = createContext();


class ContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: createTheme({
                palette: {
                    primary: { 500: "#007DFE" },
                    secondary: { A400: "#f50057" },
                    white: { 500: "rgba(255,255,255)" }
                },
            }),
            selectedGame: {},
            cardsDetails: []
        }
    }
    componentDidMount() {

    }
    setSelectedGame(_data) {
        this.setState({ selectedGame: _data })
    }
    setCardsDetails(_data) {
        this.setState({ cardsDetails: _data })
    }
    render() {
        return (
            <ContextData.Provider value={{
                theme: this.state.theme,
                selectedGame: this.state.selectedGame,
                setSelectedGame: this.setSelectedGame.bind(this),
                setCardsDetails: this.setCardsDetails.bind(this),
                cardsDetails: this.state.cardsDetails,
            }}>
                {this.props.children}
            </ContextData.Provider>
        )
    }
}

export default ContextProvider;
