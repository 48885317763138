import axios from 'axios';

const serverIP = process.env.REACT_APP_SERVER_API; 

export async function fetchData(payload) {
    console.clear();
   
    return await axios({
        method: 'post',
        url: serverIP + '/products',
        responseType: 'json',
        data: payload
    });
}

export async function fetchMandiriData(payload) {
   
    return await axios({
        method: 'post',
        url: serverIP + '/inquiry',
        responseType: 'json',
        data: payload
    });
}
