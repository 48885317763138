import React, { useEffect, useState, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styles from './ErrorDialog.module.css'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ErrorDialog({ _open, _handleClose, ...props }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (_open) {
            setOpen(_open)
        }
    }, [_open])

    const handleClose = () => {
        setOpen(false);
        _handleClose(false)
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        backgroundColor: '#404246',
                        boxShadow: 'none',
                        color: 'white'
                    },
                }}
            >
                <DialogTitle>OOPS!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{color:'white'}}>
                        Something went wrong! Please try again
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" className={styles.errorButtonStyles}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
