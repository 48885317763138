import React from 'react';
import './App.css';
import AppRouter from "./AppRouter/AppRouter";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from './ContextApi/ContextProvider/ContextProvider';

function App() {
  return (
    <div>
      <ContextProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </ContextProvider>
    </div>
  );
}

export default App;
