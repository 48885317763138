import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import LandingPage from '../Pages/LandingPage/LandingPage';
import { ThemeProvider } from '@mui/material/styles';
import { ContextData } from '../ContextApi/ContextProvider/ContextProvider';
import DetailsPage from '../Pages/DetailsPage/DetailsPage';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

function AppRouter(props) {
    const { theme } = React.useContext(ContextData);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.location.pathname])
    return (
        <div>
            <ThemeProvider theme={theme}>
                {/* <NavigationBar /> */}
                <div className='root-app' style={{ minHeight: '100vh' }}>
                    <div className="root-mobile-view">
                        <Switch>
                            <Redirect from="/" to="/homepage" exact strict />
                            <Route path="/homepage" component={LandingPage} />
                            <Route path="/detailsPage/:id" component={DetailsPage} />
                        </Switch>
                    </div>
                </div>
            </ThemeProvider> 
        </div>
    )
}

export default withRouter(AppRouter);