import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import styles from './DetailsPage.module.css';
import ellipseImage from '../../Assets/ellipse.png';
import ellipseSmall from '../../Assets/ellipsesmall.png';
import rectangleImage from '../../Assets/rectangle.png';
import starImage from '../../Assets/Star.png';
import expandArrow from '../../Assets/Chevron.png';
import CustomButton from '../../Components/CustomButton/CustomButton';
import CustomDialog from '../../Components/CustomDialog/CustomDialog';
import { ContextData } from '../../ContextApi/ContextProvider/ContextProvider';
import { fetchData, fetchMandiriData } from '../../Utility/Network';
import defaultImg from '../../Assets/default.svg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CircularLoader from '../../Components/CircularLoader/CircularLoader'
import ErrorDialog from '../../Components/ErrorDialog/ErrorDialog';
function DetailsPage() {
    const { cardsDetails, selectedGame, setSelectedGame, setCardsDetails } = useContext(ContextData)
    const { id: param_id } = useParams();
    const [activeCard, setActiveCard] = useState(-1);
    const [showDetails, setShowDetails] = useState(false);
    const [errorMsg, setErrorMsg] = useState({ name: '', email: '' })
    const [isError, setIsError] = useState({ name: false, email: false })
    const [user, setUser] = useState({ name: '', email: '' });
    const [canSubmit, setCanSubmit] = useState(true);
    const [cardList, setCardList] = useState([]);
    const [showError, setShowError] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [inquiryData, setInquiryData] = useState(null)
    const [redirectUrl, setREdirectUrl] = useState('');
    const [isMyInputFocused, setIsMyInputFocused] = useState({ name: false, email: false });
    const [isLoading, setIsLoading] = useState(false);
    const handleClick = (_data) => {
        if (_data.code === activeCard) {
            setActiveCard(-1)
            setCanSubmit(true)
        } else {
            setActiveCard(_data.code)
            if (user.name !== '' && user.email !== '' && isError.email !== true && isError.name !== true) {
                setCanSubmit(false)
            }
        }
    }
    useEffect(() => {
        setCardList(cardsDetails)
        if (cardsDetails.length > 0) {
            // setActiveCard(cardsDetails[0].code)
            setSelectedCard(cardsDetails[0]);
        } else {
            // setCanSubmit(true)
        }
        return () => {
            setCardList([])
        }
    }, [cardsDetails])
    const validate = (_name, _value) => {
        let temp = {};
        let returnValue = true;
        var nameRegex = /^[a-zA-Z ]*$/;
        if (_name === 'name') {
            temp.name =
                _value !== "" &&
                    _value !== undefined &&
                    _value !== null
                    ? nameRegex.test(_value) ? ""
                        : "Nama tidak boleh kosong"
                    : 'Nama tidak boleh kosong'
        }
        const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (_name === 'email') {
            temp[_name] =
                _value !== "" &&
                    _value !== undefined &&
                    _value !== null
                    ? EmailRegex.test(_value)
                        ? ""
                        : "Alamat email tidak terdaftar"
                    : "Alamat email tidak terdaftar";
        }
        setErrorMsg({
            ...temp,
        });

        var found = Object.keys(temp).filter(function (key) {
            return temp[key].includes("email") || temp[key].includes("kosong");
        });

        if (found.length) {
            setIsError({ ...isError, [_name]: true })
            returnValue = false;
        } else {
            setIsError({ ...isError, [_name]: false })
        }
        return returnValue;
    }
    const handlePayment = () => {
        getData()
        // redirect to payment
    }

    const closeDetails = () => {
        setShowDetails(false)
    }

    const handleDetails = () => {
        setShowDetails(true)
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const inputValue = event.target.value;
        if(name === 'name' && name !== ''){
            var nameRegex = /^[a-zA-Z ]*$/;
            var textvalue = nameRegex.test(inputValue) ? true
                : false;
            if (!textvalue) {
                return
            }
        }
        if (name === 'email') {
            var value = inputValue.split(' ').join('')
        } else {
            value = inputValue
        }
        let validated = validate(name, value)
        setUser({ ...user, [name]: value })
        // if (user.name !== '' && user.email !== '' && validated) {
        //     setCanSubmit(false)
        // } else {
        //     setCanSubmit(true)
        // }

    }

    useEffect(() => {
        // console.log(user, isError, 'user')
        if (user.name !== '' && user.email !== '') {
            if (isError.email !== true && isError.name !== true && activeCard !== -1) {
                setCanSubmit(false)

            } else {
                setCanSubmit(true)
            }
        }
        if (user.name === '') {
            setCanSubmit(true)
        }
        if (user.email === '') {
            setCanSubmit(true)
        }
    }, [user, isError])

    const clearInput = (_inputName) => {
        setUser({ ...user, [_inputName]: '' })
        setIsError({ ...isError, [_inputName]: true })
        setCanSubmit(true)
    }

    const getData = async () => {
        if (canSubmit) {
            return
        }
        setIsLoading(true)
        const payloadData = {
            "productCode": activeCard,
            "emailId": user.email,
            "customerName": user.name,
            "brandName": selectedGame.name,
            "brandCode": selectedGame.code
        }
        try {
            const res = await fetchMandiriData(payloadData);
            const data = res.data;
            if (res.status === 200 && data.success) {
                setInquiryData(data.data)
                if (data.data.hasOwnProperty('extraFields') && data.data.extraFields.length > 0) {
                    setREdirectUrl(data.data.extraFields[0].value);
                    window.open(`${data.data.extraFields[0].value}`, "_self")
                }
            } else {
                //catch error
                setShowError(true)
                setIsLoading(false)
            }
        } catch (error) {
            setShowError(true)
            setIsLoading(false)
        }
        
    }
    const checkKey = (e) => {
        var key = e.which;
        if ((key >= 65 && key <= 90) || (key >= 97 && key <= 122) || key === 8 || key === 9 || key === 32) {

        } else {
            e.preventDefault()
        }
    }
    useEffect(() => {
        getBillerData()
    }, [])
    const getBillerData = async () => {
        const payloadData = {
            "brandName": param_id
        }
        try {
            const res = await fetchData(payloadData);
            const data = res.data;
            if (res.status === 200) {
                if (data.success) {
                    setCardsDetails(data.billers[0].products)
                    setSelectedGame(data.billers[0])
                } else {
                    setShowError(true)
                }

            } else {
                //catch error
                setShowError(true)
            }
        } catch (error) {
            setShowError(true)
        }
        
    }
    return (
        <div className={styles.detailsPageContainer}>
            <div className={styles.detailsBannerContainer} style={{
                backgroundImage: `url(${selectedGame.logo === '' ? defaultImg : selectedGame.logo})`,
                backgroundPosition: 'center'
            }}>
            </div>
            <div className={styles.detailsCardSection} >
                <div className={styles.inputFormContainer} >
                    <TextField
                        id="filled-name-input"
                        label="Nama Lengkap"
                        type="text"
                        autoComplete="current-password"
                        variant="filled"
                        error={isError.name}
                        name="name"
                        size="small"
                        onPaste={(e) => handleChange(e)}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => checkKey(e)}
                        pattern="[A-Za-z]+"
                        value={user.name || ''}
                        style={{ width: '100%' }}
                        onBlur={() => setIsMyInputFocused({ ...isMyInputFocused, 'name': false })}
                        onFocus={() => setIsMyInputFocused({ ...isMyInputFocused, 'name': true })}
                        InputLabelProps={{
                            style: {
                                color: `${isError.name ? '#FF6B71' : '#FFFFFF'}`, fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                            },
                        }}
                        InputProps={{
                            style: {
                                background: '#5d5e62',
                                width: '100%',
                                color: 'white',
                                fontWeight: 700,
                                fontSize: '16px',
                                lineHeight: '24px',

                            },
                            endAdornment: (
                                <InputAdornment position="start">
                                    {(user.name !== '' && !isMyInputFocused.name) && <CloseIcon style={{ width: '16px', height: '16px', color: 'white', marginTop: '-19px', border: '2px solid white', borderRadius: '50px', cursor: 'pointer' }} onClick={() => clearInput('name')} />}
                                </InputAdornment>
                            )
                        }}
                    />
                    <p style={{ fontSize: '13px', color: '#FF6B71', paddingLeft: '10px' }}>{isError.name && 'Nama tidak boleh kosong'}</p>
                    <TextField
                        id="filled-password-input"
                        label="Email"
                        type="email"
                        autoComplete="current-password"
                        variant="filled"
                        name="email"
                        size="small"
                        error={isError.email}
                        value={user.email || ''}
                        style={{ width: '100%' }}
                        onInput={(e) => handleChange(e)}
                        onPaste={(e) => handleChange(e)}
                        onBlur={() => setIsMyInputFocused({ ...isMyInputFocused, 'email': false })}
                        onFocus={() => setIsMyInputFocused({ ...isMyInputFocused, 'email': true })}
                        InputLabelProps={{
                            style: {
                                color: `${isError.email ? '#FF6B71' : '#FFFFFF'}`, fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                            },
                        }}
                        InputProps={{
                            style: {
                                background: '#5d5e62',
                                width: '100%',
                                color: 'white',
                                fontWeight: 700,
                                fontSize: '16px',
                                lineHeight: '24px',

                            },
                            endAdornment: (
                                <InputAdornment position="start">
                                    {(user.email !== '' && !isMyInputFocused.email) && <CloseIcon style={{ width: '16px', height: '16px', color: 'white', marginTop: '-19px', border: '2px solid white', borderRadius: '50px', cursor: 'pointer' }} onClick={() => clearInput('email')} />}
                                </InputAdornment>
                            )
                        }}
                    />
                    {isError.email && <p style={{ fontSize: '13px', color: '#FF6B71', paddingLeft: '10px', marginBottom: '0px' }}>Alamat email tidak terdaftar</p>}
                </div>
                <div className={styles.cardListingSection}>
                    <span>Pilih Voucher</span>
                    {cardList.length !== 0 && <React.Fragment> {cardList.map((item, index) => {
                        return (
                            <div onClick={() => handleClick(item)} key={item}>
                                {activeCard === item.code ? (
                                    <div className={styles.cardListingContainerActive} key={index}>
                                        <div className={styles.cardTextContainer}>
                                            <span>{item.name}</span>
                                            {item.amount !== undefined && <span> {item.amount}</span>}
                                        </div>
                                        <img src={ellipseImage} className={styles.ellipseRight} />
                                        <img src={ellipseSmall} className={styles.ellipseSecondary} />
                                        <img src={rectangleImage} className={styles.rectangleLeft} />
                                        <img src={starImage} className={styles.starLeft} />
                                    </div>) : (
                                    <div className={styles.cardListingContainerInactive} key={index}>
                                        <div className={styles.cardTextContainer}>
                                            <span>{item.name}</span>
                                            {item.amount !== undefined && <span> {item.amount}</span>}
                                        </div>
                                    </div>
                                )}

                            </div>
                        )
                    })}</React.Fragment>}
                    {cardList.length === 0 && <React.Fragment>
                        <div>
                            <div className={styles.cardListingContainerActive} >
                                <div className={styles.cardTextContainer}>
                                    <span>Stock Habis</span>
                                </div>
                                <img src={ellipseImage} className={styles.blackEllipseRight} />
                                <img src={ellipseSmall} className={styles.blackEllipseSecondary} />
                                <img src={rectangleImage} className={styles.blackRectangle} />
                                <img src={starImage} className={styles.blackStar} />
                            </div>
                        </div>
                    </React.Fragment>}
                    <div className={styles.cardBottomActions}>
                        {cardList.length !== 0 && <div className={styles.expandContainer} >
                            <img src={expandArrow} style={{ marginBottom: '9px' }} onClick={handleDetails} />
                            <br />
                            <span style={{ display: 'inline-block' }} onClick={handleDetails}>Lihat detail voucher</span>
                        </div>
                        }
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <CustomButton value="Bayar Sekarang" handleClick={handlePayment} buttonDisabled={canSubmit} size='56px' />
                        <div className={styles.termsConditionText} style={{ paddingBottom: '20px' }}>
                            {/* <span>Produk atau jasa ini disediakan
                                oleh PT Ayopop Teknologi
                                Indonesia. Bank Mandiri tidak
                                bertanggung jawab atas produk
                                atau jasa tersebut.</span> */}
                            {/* <span>
                                Produk atau jasa ini disediakan oleh Ayoconnect dan sepenuhnya menjadi tanggung jawab Ayoconnect . Baca Syarat dan Ketentuan layanan <a href="https://bankmandiri.co.id/livin/vouchergame/syarat-dan-ketentuan?utm_source=urban-lifestyle&utm_medium=livinapp&utm_campaign=tnc-vouchergame" className={styles.anchorClass}>di sini.</a>
                            </span> */}
                        </div>
                    </div>
                </div>

            </div>
            <CustomDialog showDialog={showDetails} handleClick={closeDetails} _data={selectedGame} />
            {isLoading && <div className={styles.overlay01}>
                <div className={styles.ProgressBarContainer}>
                    <CircularLoader />
                </div>
            </div>}
            <ErrorDialog _open={showError} _handleClose={setShowError} />
        </div>
    )
}

export default DetailsPage;


// gradient
// -webkit-gradient(
//     linear,  left top, left bottom,from(rgba(0,61,102,0.1)),
//     to(rgba(7,20,32,0.3))
// ),